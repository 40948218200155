import React from 'react';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { ItemTypes, PresentationStyles } from '@wg/wows-entities/const';

import styles from './ShipWeapons.scss';
import { t } from '~/utils/localization';

interface ShipWeaponsProps {
    shipId: number | string;
}

export function ShipWeapons({ shipId }: ShipWeaponsProps) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{t('Вооружение')}</div>
            <WoWSEntity
                type={ItemTypes.WEAPONS}
                id={shipId}
                presentation={{
                    withTooltip: true,
                    style: PresentationStyles.WEAPONS,
                }}
            />
        </div>
    );
}
