import { interpolate } from '~/utils/localization';
import styles from './CommonRewardsLayout.scss';
import classNames from 'classnames';
import ChanceLabel from '~/components/ChanceLabel/ChanceLabel';
import { GroupedRewardsTypes } from '~/Layouts/Lootboxes/utils';
import { DivTooltip } from '@wg/wows-react-uikit';
import GroupedLootboxRewardsTooltip from './GroupedLootboxRewardsTooltip';
import { openPortByItem } from '~/settings/port';
import { settings } from '~/utils/settings';
import { PortPreviewType } from '~/components/Port/settings';
import PortPreviewButton from '~/components/PortPreviewButton/PortPreviewButton';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import { ManagerData } from '~/core/ManagerData';

export const wowsEntityClassesMapByType = {
    [GroupedRewardsTypes.SIGNAL]: 'flag',
    [GroupedRewardsTypes.CAMOUFLAFE]: 'permoflages',
    [GroupedRewardsTypes.CAMOBOOST]: 'camoboost',
};

interface IGroupedRewardItem {
    amount: string;
    type: keyof typeof wowsEntityClassesMapByType;
    chance: number;
    str: string;
    rewards: ILootboxReward[];
    bundleId?: number;
    isAvailablePortPreview?: boolean;
    className?: string;
    lootboxId: number;
    theme?: ALLOWED_THEMES;
    rarity?: string;
}

const GroupedRewardItem = ({ amount, type, chance, str, rewards, bundleId, isAvailablePortPreview, className, lootboxId, theme, rarity }: IGroupedRewardItem) => {
    str = interpolate(str, { amount });
    const weType = wowsEntityClassesMapByType[type];
    const rarityType = wowsEntityClassesMapByType[type];

    const gropedTooltip = <GroupedLootboxRewardsTooltip rewards={rewards} renderTextAboutChance={Number.getCountNumbersAfterComma(chance) === 2} theme={theme} />;
    let groupedImg = null;
    if (rarity) {
        groupedImg = ManagerData.getIconByTypeAndRarity(rarityType, rarity);
    }

    const openPort = () => {
        openPortByItem(
            {
                shipId: settings.camouflagePreviewDefaultShip,
                type: type,
                identifier: rewards[0].id,
            },
            bundleId,
            false,
            PortPreviewType.CONTAINER,
        );
    };

    return (
        <DivTooltip tooltipBody={gropedTooltip} className={classNames(styles.item, className)}>
            {isAvailablePortPreview && <PortPreviewButton onlyIcon className={styles.portPreviewButton} onClick={openPort} />}
            <div className={classNames(styles.weGroupedItem, `with-icon`, !groupedImg && `we-${weType}__title`)}>
                {!!groupedImg && <img src={groupedImg} alt="" className={styles.withGroupedImg} />}
                {str}
            </div>
            <ChanceLabel chance={chance} className={styles.changeLabel} tooltipContent={[<GroupedLootboxRewardsTooltip rewards={rewards} />]} withoutTooltip />
        </DivTooltip>
    );
};

export default GroupedRewardItem;
