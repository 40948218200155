import { armoryState, settings, getPlatform } from '~/utils/settings';
import { interpolate, ngettext, t } from '~/utils/localization';
import filtersMap, { getFiltersInfo } from '~/settings/filtersMap';
import { items as ITEMS, assets as ASSETS, items, assets, CrewTypes, ModernizationTags } from '@wg/wows-entities/const';
import { isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import Account from '~/account/Account';
import { BUNDLE_TYPES } from '~/components/Bundle/BundleManager';
import store from '~/Store';
import { isSerialRandomBundle } from '~/components/Bundle/Default/Bundle';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { getFiltersData } from '~/utils/filters';
import { getPresetByName } from '~/utils/category';
import { LOCAL_STORAGE_SHINY_EFFECT, LOCAL_STORAGE_STOREFRONT_BUNDLE_WAIT_TO_BE_ADDED_TO_INVENTORY } from '~/utils/keys';
import { CATEGORIES, DENY_FOR_PLATFORMS_GROUPS, UNIQUE_ITEMS, SECRET_SANTA_GIFT_NAME } from '~/const';
import {
    arrayToObjectByKey,
    flat,
    getDeepProperty,
    getModernizationSlot,
    getSearchParam,
    isEmptyObject,
    isInGameBrowser,
    isMobileOrTabletWindow,
    redirectToLogin,
    updateSearchParam,
} from '~/utils/utils';
import { getLimitPerType, getTotalLimit, isEnabledContainerPurchaseLimit, isEnabledPurchaseLimitToContainer } from '~/components/BundleLimitWidget/settings';
import { getSumFromArray } from '~/utils/number';
import { ManagerData, getSectionName } from '~/core/ManagerData';
import { BundlePurchaseTypes, BUNDLE_DECORATION } from '~/types/bundle';
import { isCurrency } from './currencies';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { isValidDate } from './time';
import { calculateBundlePrices } from './purchase';
import { getAvailableCouponsFromBundle } from './coupons';
import { CurrencySecuenceItem } from '~/components/Tooltip/BundleCurrencySequenceTooltip';
import { loadPrimaryItemData } from '~/hooks/useLoadPrimaryItemData';
import { StorefrontEntitlement } from '@wg/wows-commerce/Storefront';
import { LocalStorageHelper } from '~/utils/storage';
import { isNeedToShowDownloadGamePopup, showDownloadGamePopup } from '~/components/Popups/settings';
import { DWH_EVENTS as WOWS_COMMERCE_DWH_EVENTS } from '@wg/wows-commerce/constants/dwhEvents';
import WSMartPurchaseProcessor from '~/processors/WSMartPurchaseProcessor';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';

export const getPurchaseExchangeRates = () => settings.purchaseExchangeRates || {};
export const getDefaultExchangeCurrency = (): ICurrencyBalanceNames => settings.defaultExchangeCurrency;
export const getSinglePurchaseMaxQuantity = () => settings.singlePurchaseMaxQuantity;

export const getExchangeRate = (bundle_currency: string) => {
    const purchaseExchangeRates = getPurchaseExchangeRates();
    const defaultExchangeCurrency = getDefaultExchangeCurrency();

    return purchaseExchangeRates[`${defaultExchangeCurrency}_${bundle_currency}`];
};

export const getPrimaryItem = (bundle: IBundle) => bundle.entitlements?.filter((item) => item.isPrimary)[0];

export const getRandomBundleChild = (
    selectedRandomBundles: {
        [key: string]: number;
    },
    bundle: IBundle,
) => {
    if (!bundle.randomBundleChildren) {
        return {};
    }

    const randomBundleChildren = arrayToObjectByKey(bundle.randomBundleChildren, 'id');
    const childBundle = randomBundleChildren[selectedRandomBundles[bundle.id]];

    return Object.assign({}, bundle, childBundle, {
        backgroundColor: childBundle?.backgroundColor || bundle?.backgroundColor,
    });
};

export const isAdmiralPack = (bundle: IBundle): boolean => {
    return bundle.decoration.includes(BUNDLE_DECORATION.ADMIRAL_PACK);
};

export const isCommanderPack = (bundle: IBundle): boolean => {
    return bundle.decoration.includes(BUNDLE_DECORATION.COMMANDER_PACK);
};

export const isSimplePack = (bundle: IBundle): boolean => {
    return bundle.decoration.includes(BUNDLE_DECORATION.SIMPLE_PACK);
};

export const isAvailableNewRandomBundleDesign = (bundle: IBundle): boolean => {
    return bundle?.decoration.includes(BUNDLE_DECORATION.NEW_RANDOM_BUNDLE);
};

export const isAvailableScrollableView = (bundle: IBundle): boolean => {
    return !isMobileOrTabletWindow && bundle?.decoration.includes(BUNDLE_DECORATION.SCROLLABLE_VIEW);
};

export const isHiddenBundleCategoryTitle = (bundle: IBundle): boolean => {
    return bundle?.decoration.includes(BUNDLE_DECORATION.HIDE_CATEGORY_TITLE);
};

export const isAvailableFullscreenTemplateWithoutGallery = (bundle: IBundle): boolean => {
    if (bundle.isRandom) {
        return false;
    }
    return !isMobileOrTabletWindow && bundle?.decoration.includes(BUNDLE_DECORATION.FULLSCREEN_WITHOUT_GALLERY);
};

export const getCountRandomBundleChildren = (bundle: IBundle) => {
    if (!bundle.randomBundleChildren) {
        return 0;
    }

    return bundle.randomBundleChildren.reduce((sum: number, item: IRandomBundleChild) => (sum += item.limitedQuantity?.personalLimit), 0);
};

export const hasUniqueItems = (bundle: IBundle): boolean => {
    if (!bundle.entitlements?.length) {
        return false;
    }

    const uniqueItems = bundle.entitlements.reduce((arr: string[], item) => {
        if (item.isUnique) {
            arr.push(item.identifier.toString());
        }
        return arr;
    }, []);

    return !!uniqueItems.length;
};

export const isShinyBundle = (bundle: IBundle): boolean => {
    if (bundle.categories.includes(CATEGORIES.PARAGONS)) {
        return true;
    }

    if (bundle.isShiny) {
        return true;
    }

    const eliteItems = bundle.entitlements.filter((item: IBundleEntity) => {
        if (item.type === ITEMS.VEHICLES) {
            return true;
        }

        if (isPermanentCamouflage(item.type)) {
            return true;
        }

        return item.isUnique && item.type === ITEMS.CREWS;
    });

    return !!eliteItems.length;
};

export const getBundlesAndCategoriesMap = (bundles: IBundleList, categories: ICategories) => {
    const bundleList: any = Object.values(bundles);

    const result = bundleList.reduce((result: any, bundle: IBundle) => {
        // @ts-ignore
        bundle.categories.forEach((bundleCategory) => {
            if (result[bundleCategory]) {
                result[bundleCategory].push(bundle.id);
            } else {
                result[bundleCategory] = [bundle.id];
            }
        });

        return result;
    }, {});

    Object.keys(result).forEach((key: string) => {
        const bundles = categories[key]?.bundles;
        if (!bundles) {
            return;
        }

        result[key] = result[key].sort((bundle1: number, bundle2: number) => {
            const index1 = bundles.indexOf(bundle1);
            const index2 = bundles.indexOf(bundle2);

            if (index1 < index2) {
                return -1;
            } else if (index2 < index1) {
                return 1;
            }

            return 0;
        });
    });

    return result;
};

export const prepareFiltersByBundles = (bundles: IBundleList, categories: ICategories) => {
    const categoriesFilters: any = {};
    const filters = filtersMap;
    const filtersInfo = getFiltersInfo();
    const categoryBundles = getBundlesAndCategoriesMap(bundles, categories);

    Object.keys(filters).forEach((category) => {
        const currentBundles = categoryBundles[category];

        if (!currentBundles || !currentBundles.length) {
            return;
        }

        const data = currentBundles.reduce((result: any, bundleId: number) => {
            const bundle = bundles[bundleId];
            if (!bundle) {
                return result;
            }

            for (const [name, values] of Object.entries(bundle.filterValues)) {
                if (result[name]) {
                    result[name].add(values);
                } else {
                    result[name] = new Set([values]);
                }
            }

            return result;
        }, {});

        Object.keys(data).forEach((key: FILTER_INFO_NAME) => {
            if (!categoriesFilters[category]) {
                categoriesFilters[category] = {};
            }

            const availableList = flat([...data[key]]);

            if (!filtersInfo[key]) {
                return;
            }

            categoriesFilters[category][key] = filtersInfo[key].items.filter((item: any) => {
                return availableList.includes(item.value);
            });
        });
    });

    window.metashop.settings.categoriesFilters = categoriesFilters;
};

export const prepareBundlesForState = (bundles: IBundleList, purchasedLimitedBundles: AccountPurchasedLimitedBundles, deniedBundlesByUniqueItems: number[], coupons: ICoupon[]) => {
    const bundleList = Object.values(bundles);

    const upBundles: IBundleList = {};

    bundleList.forEach((bundle: IBundle) => {
        bundle.filterValues = {};

        if (!bundle.isPurchased) {
            bundle.isPurchased = Account.isAlreadyPurchased(purchasedLimitedBundles, deniedBundlesByUniqueItems, bundle);
        }

        if (bundle.primaryItem) {
            bundle.primaryItem = {
                ...bundle.primaryItem,
                filterValues: Object.assign(bundle.primaryItem.filterValues || {}, {
                    ...getFiltersData(bundle.primaryItem.identifier, bundle.primaryItem.type, bundle, coupons),
                }),
            };
        } else {
            bundle.primaryItem = {
                filterValues: {
                    ...getFiltersData(null, null, bundle, coupons),
                },
            } as BundlePrimaryType;
        }

        bundle.categories.forEach((category) => {
            if (filtersMap[category]) {
                for (const filterName of filtersMap[category]) {
                    const filter = getFiltersInfo()[filterName];
                    const field = filter?.bundleField;
                    const isDisabledForBundle = !!filter?.isDisabledForBundle?.(bundle);
                    if (field && !isDisabledForBundle) {
                        bundle.filterValues[field.split('.').pop()] = getDeepProperty(field, bundle);
                    }
                }
            }
        });

        upBundles[bundle.id] = bundle;
    });

    return upBundles;
};

export const isDisplayRestricted = (obj: IDisplayRestrictedOptions): boolean => {
    let denyForCountries = obj?.denyForCountries || [];
    let allowForCountries = obj?.allowForCountries || [];

    let country = armoryState?.account?.country || 'unknown';
    country = country.toUpperCase();

    denyForCountries = denyForCountries.map((x) => x.toUpperCase());
    allowForCountries = allowForCountries.map((x) => x.toUpperCase());

    return denyByPlatforms(obj) || denyForCountries.includes(country) || (allowForCountries.length > 0 && !allowForCountries.includes(country));
};

export const isSecretGift = (gift: IGift) => {
    return gift.name === SECRET_SANTA_GIFT_NAME; // TODO: change to decoration property (need woop rework)
};

export const denyByPlatforms = (obj: IDisplayRestrictedOptions): boolean => {
    const denyForPlatforms = obj?.denyForPlatforms || [];

    const platformsGroups = isInGameBrowser ? DENY_FOR_PLATFORMS_GROUPS.GAME_CLIENT : DENY_FOR_PLATFORMS_GROUPS.WEB;

    return denyForPlatforms.includes(getPlatform()) || platformsGroups.some((platform) => denyForPlatforms.includes(platform));
};

export const getParentRandomBundleByChild = (bundles: IBundleList, bundleChildId: number): number => {
    return Object.values(bundles).reduce((id: number, bundle) => {
        if (!bundle.randomBundleChildren) {
            return id;
        }

        const children = bundle.randomBundleChildren.map((item: IRandomBundleChild) => item.id);

        if (children.includes(bundleChildId)) {
            id = +bundle.id;
        }
        return id;
    }, null);
};

export const getChildBundle = (bundles: IBundleList, bundleChildId: number): IBundle => {
    const parentBundle = bundles[getParentRandomBundleByChild(bundles, bundleChildId)];
    if (!parentBundle) {
        return null;
    }

    const randomBundleChildren = arrayToObjectByKey(parentBundle.randomBundleChildren, 'id');

    return Object.assign({}, parentBundle, randomBundleChildren[bundleChildId]);
};

export const getAlreadyHaveText = (bundle: IBundle, isShortText = false) => {
    let text;

    const primaryItemType = bundle?.primaryItem?.type;

    const map: any = {
        ship: t('У вас уже есть этот корабль'),
        crew: t('У вас уже есть этот командир'),
        other: t('Набор или уникальное имущество из набора получены.'),
    };

    const shortMap: any = {
        ship: t('Корабль получен'),
        crew: t('Командир получен'),
        other: t('Имущество получено'),
    };

    if (primaryItemType) {
        if ((primaryItemType === items.VEHICLES && !isOnlyShipBundle(bundle)) || (primaryItemType === items.CREWS && !notShipBundle(bundle))) {
            text = isShortText ? shortMap['other'] : map['other'];
        } else {
            text = isShortText ? shortMap[primaryItemType] : map[primaryItemType];
        }
    }

    if (!text && hasUniqueItems(bundle)) {
        text = isShortText ? t('Имущество получено') : t('Набор или уникальное имущество из набора получены.');
    }

    return text || t('Имущество получено');
};

export const getSerialBundlesById = (bundles: IBundleList, id: number) => {
    let bundle = Object.values(bundles).filter((item: IBundle) => item.serialSequence?.includes(id))[0];
    if (!bundle) {
        bundle = Object.values(bundles).filter((item: IBundle) => {
            if (!item.randomBundleChildren) {
                return false;
            }

            const arrayIds = item.randomBundleChildren.map((child) => child.id);

            return arrayIds.includes(id);
        })[0];
    }

    if (!bundle) {
        bundle = bundles[id];
    }

    if (!bundle) {
        return [];
    }

    return [bundle.id, ...bundle.serialSequence]
        .map((bundleId) => {
            return bundles[bundleId];
        })
        .filter((bundle) => !!bundle);
};

export const getCountAlreadyPurchasedSerialBundles = (purchasedLimitedBundles: AccountPurchasedLimitedBundles, serialBundles: IBundle[]) => {
    const ids = serialBundles.map((bundle) => bundle.id);

    return ids.reduce((count: number, item: any) => {
        if (purchasedLimitedBundles[item.id]) {
            count += 1;
        }

        return count;
    }, 0);
};

export const getPriceFromAllBundles = (bundle: IBundle, purchasedLimitedBundles: AccountPurchasedLimitedBundles) => {
    const enabledPacksCount =
        bundle.randomBundleChildren.reduce((count, child) => {
            const alreadyPurchasedCount = purchasedLimitedBundles[child.id] || 0;
            if (alreadyPurchasedCount < child.limitedQuantity?.personalLimit) {
                count += child.limitedQuantity?.personalLimit - alreadyPurchasedCount;
            }
            return count;
        }, 0) || 1;

    const prices = {
        [bundle.currency]: enabledPacksCount * (bundle.price as number),
        [bundle.additionalCurrency]: enabledPacksCount * (bundle.price as number),
    };

    return prices;
};

export const getUniqueBundles = (bundles: IBundle[]): IBundle[] => {
    const uniqueBundles: IBundle[] = [];

    bundles.forEach((bundle) => {
        const items: any = bundle.entitlements;
        bundles.forEach((_bundle) => {
            const bundleItems: any = _bundle.entitlements;
            const result = Object.keys(bundleItems).every((key: string) => bundleItems[key] !== items[key]);
            if (result) {
                uniqueBundles.push(bundle);
            }
        });
    });

    return uniqueBundles;
};

export const isAvailableQuantityWidget = (bundle: IBundle) => {
    if (bundle.type === 'periodic') {
        return false;
    }

    const bundleLimit = bundle.limitedQuantity?.personalLimit || bundle.singlePurchaseMaxQuantity || getSinglePurchaseMaxQuantity();
    return Account.canChangeQuantity(bundle) && bundleLimit && bundleLimit >= 2;
};

export const isMultiplePurchaseAvailable = (bundle: IBundle) => {
    if (bundle.type === 'random' || isSerialRandomBundle(bundle) || bundle.type === 'periodic') {
        return false;
    }

    const arrayForLimit = [bundle.limitedQuantity?.personalLimit, bundle.singlePurchaseMaxQuantity, getSinglePurchaseMaxQuantity()].filter((val) => !!val);
    const bundleLimit = Math.min.apply(null, arrayForLimit);

    return bundleLimit && bundleLimit > 1 && !hasUniqueItems(bundle);
};

export const getPurchaseLimit = (bundle: IBundle) => {
    const account = store.getState().ReducerAccount;
    const purchasedLimitedBundles = account.purchasedLimitedBundles || {};
    const purchasedCount = purchasedLimitedBundles[bundle.id] || 0;

    if (!bundle.limitedQuantity) {
        return null;
    }

    const limit = bundle.limitedQuantity?.personalLimit - purchasedCount || 0;

    if (!limit) {
        return null;
    }

    return limit;
};

export const isPeriodicBundle = (bundle: IBundle) => {
    return bundle?.type === BUNDLE_TYPES.periodic;
};

export const isSerialBundle = (bundle: IBundle) => {
    return bundle?.type === BUNDLE_TYPES.serial || bundle?.serialPurchase;
};

export const isFreeBundle = (bundle: IBundle): boolean => {
    return bundle.price === 0 || bundle.originalProduct?.price === 0;
};

export const getFirstSerialBundle = (bundle: IBundle): IBundle => {
    const allBundles = store.getState().ReducerApp.bundles;
    if (!bundle.dependentBundle && isSerialBundle(bundle)) return bundle;
    if (bundle.dependentBundle && allBundles[bundle.dependentBundle]) return getFirstSerialBundle(allBundles[bundle.dependentBundle]);
    return null;
};

export const isSerialBundlePurchased = (bundle: IBundle): boolean => {
    const allBundles = store.getState().ReducerApp.bundles;
    if (!isSerialBundle(bundle)) return false;
    const firstBundle = getFirstSerialBundle(bundle);
    return !!firstBundle?.serialSequence?.every((bundleId: number) => allBundles[bundleId].isPurchased);
};

export const isBundlePurchased = (bundle: IBundle): boolean => {
    if (isSerialBundle(bundle)) return isSerialBundlePurchased(bundle);
    return !!bundle.isPurchased;
};

export const isTimeUpForPeriodicBundleObtain = (bundle: IBundle) => {
    const now = Date.now();
    const purchaseAvailableTill = new Date(bundle.promoTimerActiveTill).getTime();
    const nextTime = getNextTimeForPeriodicBundle(bundle);
    const nextDate = new Date(nextTime).getTime();
    return purchaseAvailableTill <= now || nextDate >= purchaseAvailableTill;
};

export const isPeriodicBundlePurchaseDisabled = (bundle: IBundle) => {
    const account = store.getState().ReducerAccount;

    return !account?.selectedRandomBundles?.[bundle.id];
};

export const isRandomBundle = (bundle: IBundle): boolean => {
    return bundle.type === 'random' || bundle.type === 'periodic' || isSerialRandomBundle(bundle);
};

/**
 * Доступен ли рандомный бандл к открытию. Открытие рандом бандла это не его покупка, это получение первого рандомного набора в серии.
 * Пока рандомный бандл не открыт, его нельзя купить.
 * @param bundle
 */
export const isRandomBundleOpeningEnabled = (bundle: IBundle): boolean => {
    const account = store.getState().ReducerAccount;
    if (!account?.openedRandomBundles || !bundle.isRandom) {
        return false;
    }

    return !isBundleDisabledByPromoTimer(bundle) && !account.openedRandomBundles.includes(bundle.id);
};

export const getNextTimeForPeriodicBundle = (bundle: IBundle) => {
    const now = Date.now();
    const coolDown = bundle.randomPurchaseCooldown * 1000;
    const activeFrom = new Date(bundle.promoTimerActiveFrom).getTime();
    const periods = Math.abs((now - activeFrom) % coolDown);

    return now + coolDown - periods;
};

export const getLatestTimeForPeriodicBundle = (bundle: IBundle) => {
    const coolDown = bundle.randomPurchaseCooldown * 1000;
    return getNextTimeForPeriodicBundle(bundle) - coolDown;
};

export const groupBundlesByName = (bundlesIds: number[]) => {
    const app = store.getState().ReducerApp;
    const bundles = app.bundles;

    const groupNames: Array<string> = [];
    const groupedBundles = bundlesIds.reduce((state: Record<string, IBundle[]>, bundleId: number) => {
        const bundle = bundles[bundleId];
        if (!bundle.groupName) {
            return state;
        }

        if (!state[bundle.groupName]) {
            groupNames.push(bundle.groupName);
            state[bundle.groupName] = [];
        }

        state[bundle.groupName].push(bundle);
        return state;
    }, {});

    groupNames.forEach((groupName) => {
        groupedBundles[groupName].sort((a: IBundle, b: IBundle) => a.groupIndex - b.groupIndex);
    });

    return groupedBundles;
};

export const isDisabledPurchasePeriodicBundle = (bundle: IBundle): boolean => {
    const isAccount = Account.getAccount();
    const _isPeriodicBundle = isPeriodicBundle(bundle);
    const isEnabledFreeOpen = isAccount && isRandomBundleOpeningEnabled(bundle);
    const _isAlreadyPurchased = bundle.isPurchased;

    return isAccount && !isEnabledFreeOpen && _isPeriodicBundle && isPeriodicBundlePurchaseDisabled(bundle) && !_isAlreadyPurchased;
};

export const isAllSequencePurchased = (bundles: IBundle[]) => {
    return bundles.every((bundle) => bundle.isPurchased);
};

export const getSerialSequence = (bundles: IBundleList, bundle: IBundle): IBundle[] => {
    if (bundle.serialPurchase && !bundle.dependentBundle) {
        return [bundle].concat(bundle.serialSequence.map((bundleId) => bundles[bundleId]));
    }
    return getSerialSequence(bundles, bundles[bundle.dependentBundle]);
};

export const showPurchasePopupFromSearchParamsIfNeeded = () => {
    const bundleIdSearchParam = getSearchParam('bundleId');
    const purchaseSearchParam = getSearchParam('purchase');
    const bundles = store.getState().ReducerApp.bundles;
    const bundle = bundles[bundleIdSearchParam];
    const purchasedLimitedBundles = store.getState().ReducerAccount.purchasedLimitedBundles;
    const isPurchaseSearchParams = !!(purchaseSearchParam === 'true' && bundleIdSearchParam);
    const isBundleAvailable = !!(bundle || getParentRandomBundleByChild(bundles, Number(bundleIdSearchParam)));
    const isDisabledPurchaseForSerialBundle = Account.isDisabledSerialBundle(purchasedLimitedBundles, bundle);

    if (!isPurchaseSearchParams || !(isBundleAvailable || !Account.getAccount())) {
        updateSearchParam({ bundleId: null, purchase: null });
        return;
    }

    if (!Account.getAccount()) {
        redirectToLogin();
        return;
    }

    if (bundle.isRandom && bundle.isPurchased) {
        updateSearchParam({ bundleId: null, purchase: null });
        return;
    }

    if (!(bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) && isNeedToShowDownloadGamePopup()) {
        showDownloadGamePopup();
        return;
    }

    if (isRandomBundleOpeningEnabled(bundle) || isDisabledPurchasePeriodicBundle(bundle) || isBundleDisabledByPromoTimer(bundle)) {
        updateSearchParam({ bundleId: null, purchase: null });
        return;
    }

    store.dispatch<IChangeVisiblePopup>(changeVisiblePopup(null));

    if (bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) {
        if (bundle.isPurchased) {
            store.dispatch(
                changeVisiblePopup(POPUPS_NAME.CONFIRM_PURCHASE, true, {
                    bundleId: bundleIdSearchParam,
                }),
            );
        } else {
            const wsmartPurchaseProcessor = new WSMartPurchaseProcessor(bundle.originalProduct, WoWsCommerceStorefrontNames.METASHOP);
            wsmartPurchaseProcessor.setBundle(bundle);
            wsmartPurchaseProcessor.buy(WOWS_COMMERCE_DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_BUNDLE, bundle.originalProduct?.quantity || 1);
        }
        return;
    }

    if (
        (bundle.serialPurchase && bundle.serialIndex === 0 && bundle.isPurchased) ||
        (bundle.serialPurchase && isDisabledPurchaseForSerialBundle) ||
        (bundle.serialPurchase && isAllSequencePurchased(getSerialSequence(bundles, bundle)))
    ) {
        updateSearchParam({ bundleId: null, purchase: null });
        return;
    }

    store.dispatch(
        changeVisiblePopup(POPUPS_NAME.CONFIRM_PURCHASE, true, {
            bundleId: bundleIdSearchParam,
        }),
    );
};

export const isParagonBundle = (bundle: IBundle): boolean => {
    return bundle?.currency === ASSETS.PARAGON_XP || bundle?.additionalCurrency === ASSETS.PARAGON_XP;
};

export const isNeedRenderGearWheel = (bundle: IBundle): boolean => {
    return true;
};

export const isEnabledTooltipFromItemByType = (type: string) => {
    return (
        type &&
        [
            items.VEHICLES,
            items.CREWS,
            items.PERMOFLAGES,
            items.CAMOUFLAGE,
            items.STYLE,
            items.SKIN,
            items.MODERNIZATION,
            items.SIGNAL,
            items.ENSIGNS,
            items.DOG_TAG,
            items.LOOTBOX,
            items.BACKGROUND,
            items.PATCH,
            items.EMBLEM,
            items.CAMO_BOOST,
            items.MULTI_BOOST,
            items.GLOBAL_BOOST,
        ].includes(type as items)
    );
};

type AllBundlePricesInfo_Types = {
    [key in ICategoryList]?: {
        [key: string]: number[];
    };
};
export const prepareCategoryBundlesPricesInfo = (bundles: IBundleList, isSettingDefaultValue = false): IBundlePricesInfo => {
    const bundlesPricesInfo = Object.keys(bundles).reduce?.((state: AllBundlePricesInfo_Types, bundleId) => {
        const bundle = bundles[bundleId] as IBundle;
        if (bundle.price === undefined || bundle.price === null || (bundle && isFreeBundle(bundle))) {
            return state;
        }

        bundle.categories.forEach((category) => {
            const categoryName = category as ICategoryList;
            const prices = getBundleCurrenciesMap(bundle);

            if (!state[categoryName]) {
                state[categoryName] = {};
            }

            const currentBundleStateInfo = state[categoryName] || {};
            Object.keys(prices).forEach((currencyName: string) => {
                if (!currentBundleStateInfo[currencyName]) {
                    currentBundleStateInfo[currencyName] = [prices[currencyName]];
                } else {
                    currentBundleStateInfo[currencyName].push(prices[currencyName]);
                }
            });
        });

        return state;
    }, {});

    return Object.keys(bundlesPricesInfo).reduce((result: IBundlePricesInfo, categoryName: ICategoryList) => {
        const category = bundlesPricesInfo[categoryName];
        if (!result[categoryName]) {
            result[categoryName] = {};
        }

        Object.keys(category).forEach((currencyName: string) => {
            const values = category[currencyName];
            result[categoryName] = {
                ...(result[categoryName] || {}),
                [currencyName]: { min: Math.min(...values), max: Math.max(...values) },
            };
            if (isSettingDefaultValue) {
                result[categoryName][currencyName].defaultMin = Math.min(...values);
                result[categoryName][currencyName].defaultMax = Math.max(...values);
            }
        });

        return result;
    }, {});
};

export const getPresetBundles = (category: ICategoryList, categories: ICategories, bundles: IBundleList, presetName: string) => {
    const presetBundles = getPresetByName(categories?.[category]?.filters?.presets || [], presetName).bundles || [];
    const availableBundles: IBundle[] = [];
    presetBundles.forEach((bundleId: number) => availableBundles.push(bundles[bundleId]));

    return availableBundles;
};

export const getRandomBundleShinyLSKey = (bundle: IBundle, index = 0) => {
    return LOCAL_STORAGE_SHINY_EFFECT.replace('{}', bundle?.id?.toString())
        .replace('{}', Account.getAccount()?.id?.toString())
        .replace('{}', index.toString());
};

export const isAvailableShinyAnimation = (bundle: IBundle, index: number) => {
    if (isMobileOrTabletWindow) {
        return false;
    }

    if (!bundle?.isShiny) {
        return false;
    }

    return !Account.isViewedShinyAnimationFromRandomBundle(bundle, index);
};

export const isBundleForGold = (bundle: IBundle) => {
    return bundle.currency === ASSETS.GOLD || bundle.additionalCurrency === ASSETS.GOLD;
};

export const isContainLootbox = (bundle: IBundle, parentRandomBundle?: IBundle, totalForWholePurchase?: number) => {
    if (parentRandomBundle) {
        if (totalForWholePurchase === bundle.quantityData?.quantity) {
            return bundle.randomBundleChildren.some((childBundle) => {
                return childBundle.entitlements?.some((item) => item.type === items.LOOTBOX);
            });
        }
    }
    return bundle.entitlements?.some((item) => item.type === items.LOOTBOX);
};

export const isNeedToShowAdultCopyright = (bundle: IBundle) => {
    if (!isBundleForGold(bundle) || bundle.hideAdultCheck) {
        return false;
    }
    return bundle.isRandom || isContainLootbox(bundle);
};

export const isOnlyShipBundle = (bundle: IBundle) => {
    const bundleItems = bundle.entitlements.filter((item: IBundleEntity) => item.type !== items.DEFAULT_CREW && item.type !== assets.SLOT);
    return bundleItems.length === 1 && bundleItems[0].type === items.VEHICLES;
};

export const notShipBundle = (bundle: IBundle) => {
    const bundleItems = bundle.entitlements.filter((item: IBundleEntity) => item.type === items.VEHICLES);
    return bundleItems.length < 1;
};

export const getAllItemsCd = (bundles: IBundleList) => {
    const bundleItemIdsByType: Record<string, Set<number>> = {};

    Object.values(bundles).forEach((bundle: IBundle) => {
        const primaryItem = bundle.primaryItem;

        if (bundle.randomBundleChildren?.length) {
            bundle.randomBundleChildren.forEach((child) => {
                child.entitlements.forEach((childItem) => {
                    if (!childItem.identifier) {
                        return;
                    }

                    if (!bundleItemIdsByType[childItem.type]) {
                        bundleItemIdsByType[childItem.type] = new Set();
                    }

                    bundleItemIdsByType[childItem.type].add(childItem.identifier);
                });
            });
        }

        bundle.entitlements.forEach((item) => {
            if (!item.type || !item.identifier) {
                return;
            }
            if (!bundleItemIdsByType[item.type]) {
                bundleItemIdsByType[item.type] = new Set();
            }
            bundleItemIdsByType[item.type].add(item.identifier);
        });

        if (!primaryItem || !primaryItem.identifier) {
            return;
        }

        if (!bundleItemIdsByType[primaryItem.type]) {
            bundleItemIdsByType[primaryItem.type] = new Set();
        }

        bundleItemIdsByType[primaryItem.type].add(primaryItem.identifier);
    });

    return bundleItemIdsByType;
};

type ProgressiveDiscountCacheMap = {
    [key: number]: {
        [key: number]: DiscountDiapason;
    };
};
const progressiveDiscountCacheMap: ProgressiveDiscountCacheMap = {};
export const getProgressiveDiscountFromBundle = (bundle: IBundle, count: number) => {
    if (!bundle.progressivePurchase) {
        return null;
    }
    if (progressiveDiscountCacheMap[bundle.id]?.[count]) {
        return progressiveDiscountCacheMap[bundle.id]?.[count];
    }
    if (!progressiveDiscountCacheMap[bundle.id]) {
        progressiveDiscountCacheMap[bundle.id] = {};
    }
    progressiveDiscountCacheMap[bundle.id][count] = bundle.progressivePurchase.discountDiapasons.find((item) => {
        if (!Array.isArray(item.diapason)) {
            return false;
        }
        const [min, max] = item.diapason;
        if (!max) {
            return count <= min;
        }
        return count >= min && count <= max;
    });
    return progressiveDiscountCacheMap[bundle.id][count];
};

export const getMaxAmountForOnePurchaseRandomBundle = (bundle: IBundle) => {
    const progressiveDiscount = bundle.progressivePurchase?.discountDiapasons?.at(-1);
    if (!progressiveDiscount) {
        return null;
    }

    const [min, max] = progressiveDiscount.diapason;
    return min || 10;
};

export const getAvailableAmountForFullPurchase = (bundle: IBundle, alreadyAmountPurchased = 0) => {
    const totalCount = getCountRandomBundleChildren(bundle);
    return totalCount - alreadyAmountPurchased;
};

export const isAvailableFullPurchaseRandomBundle = (bundle: IBundle) => {
    return !isPeriodicBundle(bundle) && !bundle.randomIsInfinite && bundle.progressivePurchase?.wholeBundlePurchase?.isEnabled;
};

export const isAvailableChangeQuantityRandomBundle = (bundle: IBundle) => {
    return !isPeriodicBundle(bundle) && bundle.progressivePurchase?.partialPurchase?.isEnabled;
};

type RandomBundleMap = {
    [key: number]: IBundle;
};
export const getShinyChildRandomBundle = (randomBundleMap: RandomBundleMap, ids: number[]) => {
    const shinyBundles = ids.filter((id) => randomBundleMap[id].isShiny);
    return shinyBundles.map((id) => randomBundleMap[id]);
};

export const getMaxQuantityForRandomBundleWithLootboxReward = (
    currentBundle: IBundle,
    bundle: IBundle,
    restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount,
    purchasedLimitedBundles: AccountPurchasedLimitedBundles,
) => {
    if (!isEnabledContainerPurchaseLimit()) {
        return null;
    }

    const itemsWithLootbox = bundle.randomBundleChildren?.filter((child) => {
        const deepBundle = Object.assign({}, bundle, child);
        return isEnabledPurchaseLimitToContainer(deepBundle);
    });

    if (!itemsWithLootbox.length) {
        return null;
    }

    const currentLootboxAmount = currentBundle.entitlements.reduce((accumulator: number, item) => {
        if (item.type === items.LOOTBOX) {
            accumulator += item.amount;
        }
        return accumulator;
    }, 0);

    let maxAmount = 0;
    let lootboxId: number = null;

    itemsWithLootbox.forEach((_bundle) => {
        _bundle.entitlements.forEach((item) => {
            if (item.type === items.LOOTBOX) {
                if (purchasedLimitedBundles?.[_bundle.id] >= _bundle.limitedQuantity?.personalLimit) {
                    return;
                }
                if (item.amount > maxAmount) {
                    maxAmount = item.amount;
                    lootboxId = item.identifier;
                }
            }
        });
    });

    const perLimit = getLimitPerType();
    const totalLimit = getTotalLimit();
    const totalPurchased = getSumFromArray(Object.values(restrictedLootboxesPurchaseCount || []));
    const availableTotalForPurchaseByTotalLimit = totalLimit - totalPurchased;
    const availableTotalForPurchaseByPerLimit = perLimit - (restrictedLootboxesPurchaseCount?.[+lootboxId] || 0);

    if (!currentLootboxAmount && totalPurchased >= totalLimit) {
        return 1;
    }

    let techLimit = availableTotalForPurchaseByPerLimit;
    if (availableTotalForPurchaseByTotalLimit < availableTotalForPurchaseByPerLimit) {
        techLimit = availableTotalForPurchaseByTotalLimit;
    }

    const value = Math.floor(techLimit / maxAmount);
    if (value + currentLootboxAmount === techLimit) {
        return value + currentLootboxAmount;
    }

    return value;
};

export const canBoughtRandomBundleWithMaxAmount = (amount: Nullable<number>) => {
    return amount === null || amount > 0;
};

export const isAvailablePreviewAmountOnBundleCard = (type: string) => {
    // @ts-ignore
    return [items.SIGNAL, items.CAMOUFLAGE, items.CAMO_BOOST, items.LOOTBOX].includes(type);
};

export const isDisabledCategoryVideoForBundlePage = (bundle: IBundle) => {
    return bundle?.decoration?.includes(BUNDLE_DECORATION.TURN_OFF_CATEGORY_VIDEO);
};

export type BundleTypeFunc<T> = (item?: IBundleEntity, withoutAdditionalContent?: boolean) => T;
export interface BundleType {
    [items.CREWS]: BundleTypeFunc<string>;
    [items.PERMOFLAGES]: BundleTypeFunc<string>;
    [items.SKIN]: BundleTypeFunc<string>;
    [items.STYLE]: BundleTypeFunc<string>;
    [items.MSKIN]: BundleTypeFunc<string>;
    [items.CAMOUFLAGE]: BundleTypeFunc<string>;
    [items.SIGNAL]: BundleTypeFunc<string>;
    [items.ENSIGNS]: BundleTypeFunc<string>;
    [items.GLOBAL_BOOST]: BundleTypeFunc<string>;
    [items.MULTI_BOOST]: BundleTypeFunc<string>;
    [items.CAMO_BOOST]: BundleTypeFunc<string>;
    [items.EMBLEM]: BundleTypeFunc<string>;
    [items.PATCH]: BundleTypeFunc<string>;
    [items.BACKGROUND]: BundleTypeFunc<string>;
    [items.SUBSTRATE]: BundleTypeFunc<string>;
    [items.DOG_TAG]: BundleTypeFunc<string>;
    [items.LOOTBOX]: BundleTypeFunc<string>;
    [items.MODERNIZATION]: BundleTypeFunc<string | Array<string>>;
}

export type DollTypes = items.PATCH | items.EMBLEM;
export type BoostTypes = items.CAMO_BOOST | items.MULTI_BOOST | items.GLOBAL_BOOST;

export const BUNDLE_TYPE: BundleType = {
    [items.CREWS]: (item: IBundleEntity) => {
        const crew = ManagerData.getCrew(item.identifier);
        return crew?.l10nRarity;
    },
    [items.PERMOFLAGES]: () => t('Постоянный камуфляж'),
    [items.SKIN]: () => t('Постоянный камуфляж'),
    [items.STYLE]: () => t('Постоянный камуфляж'),
    [items.MSKIN]: () => t('Постоянный камуфляж'),
    [items.CAMOUFLAGE]: () => t('Расходуемый камуфляж'),
    [items.SIGNAL]: (item: IBundleEntity) => {
        const signal = ManagerData.getItem(item.identifier);
        return signal?.l10nRarity;
    },
    [items.ENSIGNS]: () => t('Памятный флаг'),
    [items.GLOBAL_BOOST]: () => t('Постоянный бонус'),
    [items.MULTI_BOOST]: () => t('Постоянный бонус'),
    [items.CAMO_BOOST]: (item: IBundleEntity) => {
        const boost = ManagerData.getItem(item.identifier);
        return boost?.l10nRarity;
    },
    [items.EMBLEM]: () => t('Эмблема'),
    [items.PATCH]: (item: IBundleEntity) => {
        const doll = ManagerData.getDoll(item.identifier);
        return doll?.isPatch ? t('Нашивка') : t('Символ');
    },
    [items.BACKGROUND]: () => t('Форма'),
    [items.SUBSTRATE]: () => t('Форма'),
    [items.DOG_TAG]: (item: IBundleEntity) => {
        const doll = ManagerData.getDoll(item.identifier);
        return BUNDLE_TYPE[doll?.type as DollTypes]?.(item);
    },
    [items.LOOTBOX]: (item: IBundleEntity) => {
        const lootbox = ManagerData.getLootbox(item.identifier);
        if (lootbox?.isPremium) {
            return t('Премиум контейнер');
        }
        return t('Контейнер');
    },
    [items.MODERNIZATION]: (item: IBundleEntity, withoutAdditionalContent?: boolean) => {
        const modernization = ManagerData.getItem(item.identifier);

        let modernizationText = t('Модернизация');
        if (modernization?.tags?.includes(ModernizationTags.SPECIAL)) {
            modernizationText = t('Особая модернизация');
        } else if (modernization?.tags?.includes(ModernizationTags.UNIQUE)) {
            modernizationText = t('Элитная модернизация');
        }

        const modernizationSlotText = withoutAdditionalContent ? null : getModernizationSlot(modernization, true);

        return modernizationSlotText ? [modernizationText, modernizationSlotText] : modernizationText;
    },
};

export const isHiddenAmountForBundle = (bundle: IBundle) => {
    const { entitlements: items } = bundle;
    if (items.length > 1) {
        return true;
    }

    const item = items[0];

    return isCurrency(item.type) || item.type === assets.WOWS_PREMIUM;
};

export const getSinglePurchaseLimitTextForRandom = (amount: number) => {
    return interpolate(
        ngettext('Единовременно можно приобрести не более {amount} случайного набора этого типа. ', 'Единовременно можно приобрести не более {amount} случайных наборов этого типа. ', amount),
        { amount },
    );
};

export const getBundleDiscount = (bundle: IBundle) => {
    let bundleDiscount: number = null;
    if (bundle.originalPrice) {
        if (bundle.discount) {
            bundleDiscount = bundle.discount;
        } else {
            bundleDiscount = 100 - Math.floor(((bundle.price as number) * 100) / (bundle.originalPrice as number));
        }
    }

    return bundleDiscount;
};

export type PricesMapType = Record<string, number>;
export const getBundleCurrenciesMap = (bundle: IBundle): PricesMapType => {
    const prices = { [bundle.currency]: bundle.price as number };
    if (bundle.additionalCurrency) {
        prices[bundle.additionalCurrency] = bundle.additionalPrice as number;
    }
    return prices;
};

export const isSteelExchangeBundle = (currencies: string[]) => {
    return currencies.includes(assets.STEEL) && currencies.includes(assets.COAL);
};

export function checkEntitlementIsUnique(entitlements: StorefrontEntitlement[]) {
    return entitlements.map((entitlement) => {
        if (UNIQUE_ITEMS.includes(entitlement.type)) {
            entitlement.isUnique = true;
        }
        return entitlement;
    });
}

type EntitlementItem = IBundleEntity | StorefrontEntitlement;
export function checkUniqueItemIsInInventory(item: EntitlementItem, inventory?: InventoryState): boolean {
    const [isExistsItemInInventory] = Account.getInventoryInfoByType(item.type, item.identifier, null, null, null, inventory);
    return item.isUnique && isExistsItemInInventory;
}

export const bundlePurchaseBlockedByInventory = (entitlements: EntitlementItem[], inventory?: InventoryState) => {
    return entitlements.some((item) => checkUniqueItemIsInInventory(item, inventory) && !item.allowCompensation);
};

export const updateBundlesPurchasedForState = (bundles: IBundleList, inventory: InventoryState) => {
    const bundleList = Object.values(bundles);
    const upBundles: IBundleList = {};
    const storedBundle = LocalStorageHelper.get(LOCAL_STORAGE_STOREFRONT_BUNDLE_WAIT_TO_BE_ADDED_TO_INVENTORY);

    bundleList.forEach((bundle: IBundle) => {
        if (bundle.productCode) {
            const bundleId = bundle.id.toString();
            const hasBundleInInventory = bundlePurchaseBlockedByInventory(bundle.entitlements, inventory);
            const bundleIsStored = storedBundle === bundleId;

            if (!bundle.isPurchased) {
                bundle.isPurchased = hasBundleInInventory || bundleIsStored;
            }
            if (bundle.isPurchased && hasBundleInInventory && bundleIsStored) {
                LocalStorageHelper.remove('purchased_bundle');
            }
        }

        upBundles[bundle.id] = bundle;
    });

    return upBundles;
};

export const updateBundleIsPurchased = (bundles: IBundleList, bundleId: number) => {
    bundles[bundleId].isPurchased = true;
    return bundles;
};

export const mergeBundles = (bundles: IBundleList, products: ProductEntity[]) => {
    const copiedBundles = { ...bundles };
    const productsMapWithCode = arrayToObjectByKey(products, 'code');
    const bundlesForMerge = Object.values(copiedBundles).filter((bundle) => !!bundle.productCode);

    bundlesForMerge.forEach((bundle) => {
        const wsmartBundle = productsMapWithCode[bundle.productCode] as WsmartProductForArmory;
        if (!wsmartBundle) {
            return;
        }
        const entitlementsWithUnique = checkEntitlementIsUnique(wsmartBundle.entitlements);
        const storedBundle = window.localStorage.getItem('purchased_bundle');

        Object.assign(copiedBundles[bundle.id], {
            productId: wsmartBundle.id,
            entitlements: entitlementsWithUnique,
            currency: wsmartBundle.currency,
            price: wsmartBundle.formattedPrice,
            originalPrice: wsmartBundle.originalPrice,
            discount: wsmartBundle.discount,
            promoTimerActiveTill: wsmartBundle.promoTimerActiveTill,
            isLoading: false,
            originalProduct: productsMapWithCode[bundle.productCode],
            isPurchased: wsmartBundle.availablePersonalCount === 0 || bundlePurchaseBlockedByInventory(entitlementsWithUnique) || storedBundle === bundle.id.toString(),
            promoLabel: wsmartBundle.promoLabels,
            limitedQuantity: {
                personalLimit: wsmartBundle.availablePersonalCount,
            },
        });
        if (copiedBundles[bundle.id].entitlements?.length === 1) {
            const item = copiedBundles[bundle.id].entitlements[0];
            item.isPrimary = true;
            const dataFromStorage = ManagerData.getData(getSectionName(item.type), item.identifier, true);
            if (!dataFromStorage) {
                loadPrimaryItemData(item.type, item.identifier);
            }
        }
        const primaryItem = getPrimaryItem(copiedBundles[bundle.id]);
        Object.assign(copiedBundles[bundle.id].primaryItem, { ...primaryItem });
    });

    return copiedBundles;
};
export const getAvailableBundlesForPurchase = (balance: IBalance, bundles: IBundle[], coupons: ICoupon[]) => {
    if (!balance) {
        return [];
    }

    const availableBundles: IBundle[] = [];
    const balanceMap = arrayToObjectByKey(balance, 'currency');
    const copiedBalance = JSON.parse(JSON.stringify(balanceMap));

    for (let i = 0; i < bundles.length; i++) {
        const prices = calculateBundlePrices(bundles[i], copiedBalance, 1, getAvailableCouponsFromBundle(coupons, bundles[i])?.[0]?.discount, null);
        const shortage = prices?.lack || {};
        if (bundles[i].isPurchased) {
            continue;
        }
        if (Object.keys(shortage)?.length) {
            return availableBundles;
        }
        Object.keys(prices.final).forEach((currency) => {
            copiedBalance[currency].value = Math.max(copiedBalance[currency].value - prices.final[currency], 0);
        });
        availableBundles.push(bundles[i]);
    }

    return availableBundles;
};

export const getValuableBundleIndexes = (bundles: IBundle[]) => {
    return bundles
        .map((bundle, index) => {
            if (bundle?.isValuableSerialBundle) {
                return bundle.serialIndex + 1;
            }
        })
        .filter((v) => !!v);
};

export const getAvailabelSerialBundleForPurchase = (sequence: IBundle[], purchasedLimitedBundles: AccountPurchasedLimitedBundles) => {
    if (!Account.getAccount()?.id) {
        return sequence[0];
    }

    return sequence.filter((bundle) => {
        return !Account.isDisabledSerialBundle(purchasedLimitedBundles, bundle) && !bundle.isPurchased;
    })[0];
};

export const getBundlePricesSequence = (bundles: IBundle[], balance: IBalance) => {
    function createTemplate(accumulator: CurrencySecuenceItem, currency: string) {
        accumulator[currency] = { value: 0, originalValue: 0, discount: 0, isSum: false };
    }

    return bundles.reduce((accumulator: CurrencySecuenceItem, item) => {
        if (!item.price) {
            return accumulator;
        }

        if (!accumulator[item.currency]) {
            createTemplate(accumulator, item.currency);
        }

        const finalPrices = calculateBundlePrices(item, balance).final;
        if ((item.currency === assets.COAL || item.additionalCurrency === assets.COAL) && finalPrices[assets.STEEL]) {
            if (!accumulator[assets.STEEL]) {
                createTemplate(accumulator, assets.STEEL);
            }
            if (!accumulator[assets.COAL]) {
                createTemplate(accumulator, assets.COAL);
            }
            if (item.additionalCurrency === assets.COAL) {
                if (!accumulator[item.currency]) {
                    createTemplate(accumulator, item.currency);
                }
                accumulator[item.currency].value += item.price as number;
                accumulator[item.currency].originalValue += (item.originalPrice || item.price) as number;
            }
            accumulator[assets.STEEL].withExchange = true;
            accumulator[assets.STEEL].value += finalPrices[assets.STEEL];
            accumulator[assets.STEEL].originalValue += finalPrices[assets.STEEL];
            accumulator[assets.COAL].value += finalPrices[assets.COAL];
            accumulator[assets.COAL].originalValue += finalPrices[assets.COAL];
        } else {
            accumulator[item.currency].value += item.price as number;
            accumulator[item.currency].originalValue += (item.originalPrice || item.price) as number;
            const discount = 100 - Math.floor((accumulator[item.currency].value * 100) / accumulator[item.currency].originalValue);
            if (discount && accumulator[item.currency].discount) {
                accumulator[item.currency].isSum = true;
            }
            accumulator[item.currency].discount = discount;
        }

        return accumulator;
    }, {});
};

export const getLastAvailableSerialBundleForPurchase = (sequence: IBundle[], balance: IBalance) => {
    if (!Account.getAccount()?.id) return sequence[0];
    let lastBundle: IBundle = null;
    for (let i = sequence.length - 1; i >= 0; i--) {
        const array = [...sequence].splice(0, i);
        const status = isEnoughCurrencyForPurchaseSerialSequence(array, balance);
        if (status.isEnought) {
            lastBundle = array.at(-1);
            break;
        }
    }
    return lastBundle;
};

export const isEnoughCurrencyForPurchaseSerialSequence = (sequence: IBundle[], balance: IBalance) => {
    if (!balance) {
        return {
            isEnought: false,
            shortageCurrencies: [],
        };
    }

    const pricesMap = getBundlePricesSequence(sequence, balance);
    const balanceMap = arrayToObjectByKey(balance, 'currency');
    const result = Object.keys(pricesMap).reduce((accumulator: Record<string, any>[], key: string) => {
        const currencyBalance = balanceMap[key];
        if (!currencyBalance) {
            return accumulator;
        }
        if (pricesMap[key].value > currencyBalance.value) {
            accumulator.push({
                currency: key,
                amount: pricesMap[key].value - currencyBalance.value,
            });
        }
        return accumulator;
    }, []);

    return {
        isEnought: isEmptyObject(result),
        shortageCurrencies: result,
    };
};

export const hasLootboxRestrictionInSerialSequence = (seqeunce: IBundle[]) => {
    return seqeunce.some((bundle) => isEnabledPurchaseLimitToContainer(bundle));
};

export const isAvailableMultiplePurchaseForSerialBundle = (bundle: IBundle, bundles: IBundleList) => {
    if (!bundle.serialPurchase) {
        return false;
    }

    const parentBundle = getSerialBundlesById(bundles, bundle.id)?.at?.(0) as IBundle;
    if (!parentBundle) {
        return false;
    }

    return parentBundle.enableMultiplePurchase !== false;
};

export const isNotAvailableBundleByCategory = (bundle: IBundle, categories: ICategories) => {
    return bundle.categories.some((categoryName: string) => {
        return categories[categoryName]?.promoTimer && isValidDate(categories[categoryName].promoTimer.timerActiveTill);
    });
};

export function isBundleDisabledByPromoTimer(bundle: IBundle): boolean {
    return !!bundle?.promoTimer?.timerActiveTill && new Date(bundle.promoTimer.timerActiveTill).getTime() > Date.now();
}

export function isBundleFromLandingCategory(bundle: IBundle, category: ICategory): boolean {
    return category?.type === CATEGORIES.LANDING && category?.bundles?.includes(bundle.id);
}

export const isModernization = (bundle: IBundle): boolean => {
    return bundle.primaryItem.type === ITEMS.MODERNIZATION;
};
