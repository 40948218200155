import { assets, items, items as ITEM_TYPES } from '@wg/wows-entities/const';
import { t } from '~/utils/localization';

export const CATEGORIES = {
    COMMANDERS: 'commanders',
    DOGTAG: 'dogtag',
    EVENT: 'event',
    EVENT_2: 'event_2',
    EVENT_3: 'event_3',
    EXTERIOR: 'exterior',
    LOOTBOX: 'containers',
    MODULES: 'modules',
    PREM_SHIPS: 'prem_ships',
    SHIPS: 'ships',
    SIGNALS: 'signals',
    PARAGONS: 'paragons',
    SHIPS_PARAGON: 'ships_paragons',
    MODULES_PARAGON: 'modules_paragons',
    SIGNALS_PARAGON: 'signals_paragons',
    SANTA_CERTIFICATES: 'new_year_certificates',
    SANTA_CERTIFICATES_ONLY: 'new_year_certificates_only',
    SANTA_CONTAINERS: 'new_year_containers',
    PURCHASE_WOWS_PREMIUM: 'premium_account',
    WSMART: 'premium',
    DOCKYARD: 'dockyard',
    MENU: 'menu',
    WALLET: 'wallet',
    COUPONS: 'coupons',
    FEATURED: 'featured',
    AUCTION: 'auction',
    SEA_BATTLE: 'seabattle',
    TRADEIN: 'tradein',
    LANDING: 'landing',
    CUSTOM_PAGE: 'custom_page',
};

export const SECRET_SANTA_GIFT_NAME = 'secret_gift_ny';

export const DOCKYARD_CATEGORY_TYPE = 'worship';

export const IFRAME_CATEGORIES: string[] = [CATEGORIES.DOCKYARD];

export const ACCEPTED_ITEMS_TYPE_FROM_ADDITIONAL_RENDER: string[] = [
    ITEM_TYPES.CREWS,
    ITEM_TYPES.VEHICLES,
    ITEM_TYPES.LOOTBOX,
    ITEM_TYPES.PERMOFLAGES,
    ITEM_TYPES.SKIN,
    ITEM_TYPES.CAMOUFLAGE,
    ITEM_TYPES.STYLE,
    ITEM_TYPES.SIGNAL,
    ITEM_TYPES.MODERNIZATION,
    ITEM_TYPES.MULTI_BOOST,
    ITEM_TYPES.GLOBAL_BOOST,
    ITEM_TYPES.CAMO_BOOST,
];

export const PURCHASE_DUPLICATE_ITEM_ERROR_CODES = ['duplicate_ship', 'duplicate_crew', 'duplicate_item'];

export const FILTER_CREW_CATEGORY_NAME = 'crew_category';
export const FILTER_CREW_CATEGORY_VALUES = {
    HISTORY: 'history',
    EVENTS: 'events',
    SPECIAL: 'special',
};

export const FILTER_CREW_TYPE_NAME = 'crew_type';
export const FILTER_CREW_TYPE_VALUES = {
    ELITE: 'elite',
    UNIQUE: 'unique',
    ADVANCED: 'advanced',
    SPECIAL: 'special',
    COMMON: 'common',
};

export const FILTER_ALLOWED_BUNDLES_NAME = 'allowed_bundles';

export const FILTER_CURRENCY_NAME = 'currency';
export const FILTER_DOLL_TYPE_NAME = 'doll_type';
export const FILTER_DOLL_TYPE_VALUES = {
    PATCH: 'patch', // нашивка
    SYMBOL: 'symbol', // символ
    BACKGROUND: 'background', // фон
};

export const NO_NATION_ITEM = {
    title: t('Без нации'),
    name: 'no_nation',
};
export const FILTER_NATION_NAME = 'nation';
export const FILTER_SHIP_CLASS_NAME = 'class';
export const FILTER_SHIP_TIER_NAME = 'tier';
export const FILTER_SHIP_LEVEL_NAME = 'level';
export const FILTER_SHIP_TYPE_NAME = 'ship_type';
export const FILTER_SHIP_TYPE_VALUES = {
    PREMIUM: 'premium',
    SPECIAL: 'special',
};

export const FILTER_CAMOUFLAGE_TYPE_NAME = 'camouflage_type';
export const FILTER_CAMOUFLAGE_TYPE_VALUES = {
    PERMANENT: 'permanent',
    EXPENDABLE: 'expendable',
};

export const FILTER_DISCOUNT_NAME = 'discount_type';
export const FILTER_DISCOUNT_VALUES = {
    COUPON: 'coupon',
};

export const FILTER_SIGNAL_TYPE = 'signal_type';

export const FILTER_SIGNAL_TYPE_VALUES = {
    HPBOOST: 'type_hpboost',
    GM: 'type_gm',
    ATBA: 'type_atba',
    SPEED: 'type_speed',
    CONSUMABLE: 'type_consumable',
    SHIFT: 'type_shift',
};

export const FILTER_BOOST_TYPE = 'boost_type';
export const FILTER_BOOST_TYPE_VALUES = {
    PERMANENT: 'permanent',
    EXPENDABLE: 'expendable',
};

export const BUNDLE_GRID_SIZE = {
    FULL: '1',
    THREE_SECOND: '3/2',
    HALF: '1/2',
    ONE_THIRD: '1/3',
    ONE_FOUR: '1/4',
    THREE_QUARTERS: '3/4',
    TWO_THIRDS: '2/3',
};

export const BUNDLE_HEIGHT_GRID = {
    ONE_HEIGHT: '1',
    THREE_SECOND: '3/2',
    TWO_HEIGHT: '2',
};

export const ACCORDION_BANNER_SIZES = {
    FULL: '1',
    HALF: '1/2',
};

export const DWH_EVENTS = {
    APP_START: 'app_start',
    USER_LOGOUT: 'user.logout',

    OPEN_COUPONS_POPUP: 'coupon.open_popup',
    OPEN_CATEGORY_FROM_COUPON_POPUP: 'coupons.open_category',
    OPEN_BUNDLE_COUPONS_POPUP: 'coupons.open_bundle_popup',

    APPLY_COUPON: 'coupon.apply',
    RESET_COUPON: 'coupon.reset',

    PURCHASE_REQUEST: 'purchase.request',
    PURCHASE_ERROR: 'purchase.error',
    PURCHASE_REQUEST_ERROR: 'purchase.request_error',
    PURCHASE_SUCCESS: 'purchase.success',
    PURCHASE_CANCEL: 'purchase.cancel',

    OPEN_PURCHASE_POPUP_FROM_CATEGORY: 'purchase_popup.open_from_category',
    OPEN_PURCHASE_POPUP_FROM_BUNDLE: 'purchase_popup.open_from_bundle',
    OPEN_PURCHASE_POPUP_FROM_PORT: 'purchase_popup.open_from_port',

    OPEN_DESCRIPTION_POPUP: 'description_popup.open',
    CLOSE_DESCRIPTION_POPUP: 'description_popup.close',

    OPEN_PORT: 'port.open',
    CLOSE_PORT: 'port.close',

    OPEN_WOWS_PREM_FROM_TOP_MENU: 'wows_prem.open_from_top_menu',
    OPEN_PREM_SHOP_FROM_TOP_MENU: 'prem_shop.open_from_top_menu',
    OPEN_WALLET: 'wallet.open',

    SEARCH_OPEN: 'search.open',
    SEARCH_GO_TO_RESULT: 'search.go_to_result',

    OPEN_CATEGORY_FROM_NOTIFICATION: 'notifications.open_category',
    CLOSE_NOTIFICATION: 'notifications.close',
    OPEN_NOTIFICATIONS: 'notifications.open_all',

    CLOSE_INFORMER: 'informer.close',
    OPEN_INFORMER: 'informer.open',

    SCROLL_TO_TOP: 'category.scroll_to_top',
    OPEN_CATEGORY: 'category.open',
    CATEGORY_VIDEO_VIEWED: 'category.video_viewed',
    CATEGORY_VIDEO_VIEWED_FULLY: 'category.video_viewed_fully',

    OPEN_BUNDLE: 'bundle.open',
    OPEN_GIFT: 'gift.open',

    FILTERS_APPLY: 'filters.apply',
    FILTERS_RESET: 'filters.reset',
    FILTERS_PRESET_APPLY: 'filters.apply_preset',
    FILTERS_POSITION: 'filters.set_position',

    FEATURING_BANNER_CLICK: 'featuring.banner_click',

    BUNDLES_SORT: 'bundles.sort',

    OPEN_LOT: 'auction.open_lot',
    BID_PLACING: 'auction.bid_placing',
    BID_PLACED: 'auction.bid_placed',
    BID_CANCELED: 'auction.bid_canceled',
    BID_PLACING_ERROR: 'auction.bid_placing_error',
    BID_INPUT: 'auction.bid_input',
    BID_DROP: 'auction.bid_drop',
    BID_RAISING: 'auction.bid_raising',
    OPEN_PREM_SHOP_FROM_AUCTION: 'auction.open_prem_shop',
    OPEN_RULES: 'auction.open_rules',

    OPEN_FAST_GOLD_POPUP: 'fastgold.open_popup',
    FASTGOLD_GO_PREMIUM_SHOP: 'fastgold.go_premium_shop',

    START_VIDEO_FOR_CATEGORY: 'category.start_video',
    CHANGE_SOUND_STATUS: 'category.change_sound_status',
    CHANGE_ANIMATION_STATUS: 'category.change_animation_status',

    PLAY_CREW_SAMPLE_VO: 'crew.play_sample_vo',

    CLIENT_GO_TO: 'client.go_to',
    PAGE_CHANGED: 'page.change',

    DIALOG_NEXT_STEP: 'dialog.next_step',
    DIALOG_PREV_STEP: 'dialog.prev_step',
    DIALOG_TOGGLE_VIEW: 'dialog.toggle_view',
    DIALOG_FINISH: 'dialog.finish',
};

export const MENU_ITEM_EVENTS_ID = 'guideEvents';

export const GET_PARAM_CLIENT_SOURCE = 'client_source';

export const CLIENT_BUTTON_COPYRIGHT_BY_ROUTE = {
    management: t('К оснащению'),
    patch: t('К знакам различия'),
    exterior: t('К внешнему виду'),
    research: t('К развитию'),
    default: t('В порт'),
    collections: t('Перейти к коллекциям'),
};

export const CLIENT_BUTTON_BY_ITEM_TYPE = {
    [ITEM_TYPES.LOOTBOX]: t('Перейти к открытию'),
};

export const CLIENT_BACK_BUTTON_BY_ROUTE: Record<string, string> = {
    collections: t('Перейти к коллекциям'),
};

export const AUCTION_STATUS: Record<string, AUCTION_STATUSES> = {
    ACTIVE: 'active',
    CHOOSING_WINNERS: 'choosing_winners',
    REWARDING_ERROR: 'rewarding_error',
    REWARDING: 'rewarding',
    FINISHED: 'finished',
};

export const BID_STATUS = {
    PLACED: 'placed',
    PLACING: 'placing',
    PLACING_ERROR: 'placing_error',

    ACTIVE: 'active',

    WON: 'won',
    WON_SENDING_REWARD: 'sending_reward',
    WON_REWARD_SENT: 'reward_sent',
    WON_REWARD_SENDING_ERROR: 'reward_sending_error',

    LOST: 'lost',
    LOST_MONEY_RETURNING: 'compensation',
    LOST_MONEY_RETURNED: 'successfully_compensated',
    LOST_MONEY_RETURNING_ERROR: 'compensation_error',
    LOST_MINIMAL_WON_BID_TIME: 'minimal_won_bid_time',

    RAISED: 'raised',
    CANCELED: 'canceled',

    CANCELING: 'canceling',
    CANCELING_ERROR: 'canceling_error',
};

export const AUCTION_KIND = {
    FIRST_PRICE: 'first_price',
    SECOND_PRICE: 'second_price',
};

export const AUCTION_LOT_PROMO_TYPE = {
    LANDING: 'landing',
    DEFAULT: 'default',
};

export const REWARD_CATEGORY = {
    AUCTION: 'auction',
    PURCHASE: 'purchase',
    FREE_BUNDLE: 'free_bundle',
};

export const REWARD_CATEGORY_TITLE = {
    [REWARD_CATEGORY.AUCTION]: t('Поздравляем, ваша ставка выйграла!'),
    [REWARD_CATEGORY.PURCHASE]: t('Поздравляем, покупка прошла успешо!'),
    [REWARD_CATEGORY.FREE_BUNDLE]: t('Поздравляем, вы получили набор!'),
};

export const QUERY_FILTER_PRESET_NAME = 'preset';
export const QUERY_FILTER_CATEGORY_NAME = 'filter_category';
export const QUERY_SORT_NAME = 'sort_name';
export const QUERY_SORT_METHOD = 'sort_method';

export const DENY_FOR_PLATFORMS_GROUPS = {
    WEB: ['web'],
    GAME_CLIENT: ['game_client'],
};

export const SUPERSHIP_ICON = '★';

export const ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY: Record<string, string> = {
    [assets.SLOT]: 'emptySlots',
    [items.VEHICLES]: 'ships',

    [items.PERMOFLAGES]: 'permoflages',
    [items.SKIN]: 'permoflages',
    [items.MSKIN]: 'permoflages',
    [items.MULTI_BOOST]: 'permoflages',

    [items.CREWS]: 'storage',
    [items.MODERNIZATION]: 'itemsStorage',
    [items.DOLLS]: 'storage',
    [items.DOG_TAG]: 'storage',
    [items.EMBLEM]: 'storage',
    [items.PATCH]: 'storage',
    [items.BACKGROUND]: 'storage',
    [items.SUBSTRATE]: 'storage',
    [items.STYLE]: 'itemsStorage',

    [items.CAMOUFLAGE]: 'itemsStorage',
    [items.CAMO_BOOST]: 'itemsStorage',
    [items.SIGNAL]: 'itemsStorage',
    [items.ENSIGNS]: 'itemsStorage',
    [items.LOOTBOX]: 'lootboxes',

    [items.SHIP_MATERIALS]: 'ship_materials',
    [items.SHIP_COMPONENTS]: 'ship_components',
};

export const SCREEN_WIDTH = {
    w1280px: 1280,
    w1770px: 1770,
    w1920px: 1920,
};
export const MOBILE_RESOLUTION = 1240;

export const PREMIUM_TIME = {
    WARSHIPS_PREMIUM: 'warships_account_premium',
    GENERAL_PREMIUM: 'general_account_premium',
};

export const CURRENCY = {
    WARSHIPS_PREMIUM: 'wows_premium',
    GENERAL_PREMIUM: 'premium',
    EVENTUM_CN: 'eventum_cn',
    EVENTUM_10: 'eventum_10',
};

export const WELCOME_GIFT_LS_KEY = 'welcome_gift_{}';

export const TAGS = {
    catalogueLegendary: 'catalogueLegendary',
};

export const UNIQUE_ITEMS: string[] = ['ship', 'permoflage', 'skin', 'hold', 'crew', 'dog_tag', 'patch', 'background', 'emblem', 'ensign', 'multiboost'];
