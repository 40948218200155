import * as React from 'react';
import classNames from 'classnames';
import styles from './ViewBackground.scss';
import equal from 'fast-deep-equal/react';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { isEmptyObject } from '~/utils/utils';
import { settings } from '~/utils/settings';
import { BlurBackgroundStatus, ICurrentPage } from '~/Actions/ActionAppType';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import { CategoryType } from '~/types/category';

interface IStateSelector {
    viewBackground: string;
    bundles: IBundleList;
    selectedRandomBundles: AccountSelectedRandomBundles;
    isTrusted: boolean;
    port: IPort;
    currentPage: ICurrentPage;
    mrpsEvents: MrpsEvent[];
    isBlurBackground: BlurBackgroundStatus;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        viewBackground: state.ReducerApp.viewBackground,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        isTrusted: state.ReducerApp.isTrusted,
        port: state.ReducerApp.port,
        bundles: state.ReducerApp.bundles,
        currentPage: state.ReducerApp.currentPage,
        mrpsEvents: state.reducerMrps.events,
        isBlurBackground: state.ReducerApp.isBlurBackground,
    };
};

interface IVideoViewBackground {
    isBlur: boolean;
    bundle: IBundle;
    category: ICategory;
}

const VideoViewBackground = ({ isBlur, bundle, category }: IVideoViewBackground) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);

    let currentBundle = bundle;
    let bundleVideoBackground = bundle?.videoBackground;

    if (isEmptyObject(bundleVideoBackground) && bundle?.parentBundleId) {
        currentBundle = state.bundles[bundle.parentBundleId];
        bundleVideoBackground = currentBundle.videoBackground;
    }

    let categoryData = category;
    let categoryVideoBackground = category?.videoBackground;
    if (category.type === CategoryType.MRPS) {
        const eventData = state.mrpsEvents?.find((event) => event.name === category.name);
        categoryVideoBackground = eventData?.videoBackground;
        /**
         ** `categoryData` used to get values of videoBackground/audioTrack/name fields, that mrpsEvent has too.
         ** That why `eventData as unknow as ICategory`
         */
        categoryData = eventData as unknown as ICategory;
    }
    const [isBlurView, setBlur] = React.useState(false);
    const isAvailableSoundEffect = useAvailableSound(categoryData, currentBundle);
    const muted = !isAvailableSoundEffect || !state.isTrusted;

    React.useEffect(() => {
        setBlur(isBlur);
    }, [isBlur]);

    if (state.port?.isVisible) {
        return null;
    }

    const backgroundColor = bundle?.backgroundColor || categoryData?.backgroundColor;
    const style = !!backgroundColor ? ({ '--bundle-video-background-color': backgroundColor } as React.CSSProperties) : {};

    const classesWrapper = classNames(styles.videoBackgroundWrapper, {
        [styles.blur]: isBlurView,
        [styles.blurBackground]: state.isBlurBackground === 1,
        [styles.unblurBackground]: state.isBlurBackground === 0,
    });

    return (
        <div className={classesWrapper} style={style}>
            <VideoBackground
                muted={muted}
                poster={state.viewBackground}
                video={isEmptyObject(bundleVideoBackground) ? categoryVideoBackground : bundleVideoBackground}
                className={classNames(state.currentPage?.name, styles[state.currentPage?.name], styles[settings.realm], {
                    [styles.videoBackground]: !!backgroundColor,
                })}
                withCustomMask={!!backgroundColor}
            >
                {!!backgroundColor && <div className={styles.videoBackgroundMask} />}
                {category.backgroundMask && !!backgroundColor && <div className={styles.backgroundCustomMask} style={{ backgroundImage: `url(${category.backgroundMask})` }} />}
            </VideoBackground>
        </div>
    );
};

export default React.memo(VideoViewBackground);
