import { UPDATE_SOUND_STATUS } from '~/Actions/ActionApp';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { VIDEO_NAMES } from '~/components/VideoPlayer/videoMap';
import { CLIENT_BUTTON_COPYRIGHT_BY_ROUTE } from '~/const';
import { PortPreviewType } from '~/components/Port/settings';
import { FilterPosition, ISortMethods, SortNames } from '~/types/category';
import { BackgroundSizes, CustomPageSettings } from '~/types/customPage';

export interface ICurrentPage {
    title?: string;
    titleImage?: string;
    name?: ICategoryList;
    isBundlePage?: boolean;
    bundleId?: string | number;
    lotId?: string;
    currencies?: string[];
    bgGallerySlideIndex?: number;
}

export type CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE';
export interface IChangeCurrentPage {
    type: CHANGE_CURRENT_PAGE;
    currentPage: ICurrentPage;
    isBlurBackground: BlurBackgroundStatus;
}

export type DATA_INITIALIZE = 'DATA_INITIALIZE';
export interface IFinishRequest {
    type: DATA_INITIALIZE;
    response: {
        vehicleTypes: any;
        nations: any;
        data: IBundleList;
        currencies: any;
    };
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    deniedBundlesByUniqueItems: number[];
}

export type CHANGE_VISIBLE_PORT = 'CHANGE_VISIBLE_PORT';
export interface IChangeVisiblePort {
    type: CHANGE_VISIBLE_PORT;
    port: {
        isVisible: boolean;
        shipId: number | null;
        exteriorId: number | null;
        portPreviewType?: PortPreviewType;
        id: number | string;
        isLoading: boolean;
        sourceUrl?: string;
        showTTX?: boolean;
        itemType?: string;
        additionalData?: Record<string, any>;
    };
}

export type CHANGE_FILTER = 'CHANGE_FILTER';
export interface IChangeFilter {
    type: CHANGE_FILTER;
    category: ICategoryList;
    name: FILTER_INFO_NAME;
    value: string;
}

export type CHANGE_MULTIPLE_FILTER = 'CHANGE_MULTIPLE_FILTER';
export interface IChangeMultipleFilter {
    type: CHANGE_MULTIPLE_FILTER;
    category: ICategoryList;
    triggerFilterName?: FILTER_INFO_NAME;
    data: {
        [key: string]: string[];
    };
}

export type RESET_FILTER = 'RESET_FILTER';
export interface IResetFilter {
    type: RESET_FILTER;
    category: string;
}

export type RESET_CATEGORY_FILTER = 'RESET_CATEGORY_FILTER';
export interface IResetCategoryFilter {
    type: RESET_CATEGORY_FILTER;
    category: string;
    withoutUpdateHistory: boolean;
}

export type UPDATE_CATEGORY_BY_FILTERS = 'UPDATE_CATEGORY_BY_FILTERS';
export interface IUpdateCategoryByFilter {
    type: UPDATE_CATEGORY_BY_FILTERS;
    category: ICategoryList;
    bundles: number[];
    bundlesList: IBundleList;
    filterName?: FILTER_INFO_NAME;
    filterValue?: string;
}

export type CHANGE_VISIBLE_POPUP = 'CHANGE_VISIBLE_POPUP';
export interface IChangeVisiblePopup {
    type: CHANGE_VISIBLE_POPUP;
    name: null | string | 'all';
    isVisible: boolean;
    data?: any;
}

export type UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export interface IUpdateQuantity {
    type: UPDATE_QUANTITY;
    bundleId: number;
    quantity: number;
    quantityData: any;
}

export type DISABLE_CATEGORY = 'DISABLE_CATEGORY';
export interface IDisableCategory {
    type: DISABLE_CATEGORY;
    category: string;
}

export type IS_FETCHING = 'IS_FETCHING';
export interface ISetFetching {
    type: IS_FETCHING;
    isFetching: boolean;
}

export type SET_VISIBLE_INFO_SCREEN = 'SET_VISIBLE_INFO_SCREEN';
export interface ISetVisibleInfoScreen {
    type: SET_VISIBLE_INFO_SCREEN;
    isVisible: boolean;
    name?: string;
}

export type SET_RANDOM_BUNDLE_ANIMATION = 'SET_RANDOM_BUNDLE_ANIMATION';
export interface ISetRandomBundleAnimation {
    type: SET_RANDOM_BUNDLE_ANIMATION;
    showAnimation: boolean;
}

export type CHANGE_VIEW_BACKGROUND = 'CHANGE_VIEW_BACKGROUND';
export interface IChangeViewBackground {
    type: CHANGE_VIEW_BACKGROUND;
    className: string;
    background?: string;
}

export type SET_VISIBLE_TOP_PANEL = 'SET_VISIBLE_TOP_PANEL';
export interface ISetVisibleTopPanel {
    type: SET_VISIBLE_TOP_PANEL;
    isVisible: boolean;
}

export type SHOW_PARALLAX_ANIMATION_SHIP = 'SHOW_PARALLAX_ANIMATION_SHIP';
export interface IShowParallaxAnimationShip {
    type: SHOW_PARALLAX_ANIMATION_SHIP;
    bundleId: number;
}

export type ON_SEARCH = 'ON_SEARCH';
export interface IOnSearch {
    type: ON_SEARCH;
    value: string;
}

export type RESET_SEARCH = 'RESET_SEARCH';
export interface IResetSearch {
    type: RESET_SEARCH;
}

export type START_VIDEO = 'START_VIDEO';
export interface IStartVideo {
    type: START_VIDEO;
    name: VIDEO_NAMES | string;
    url?: string;
    onFinish?: () => void;
    fadeOutVideoInSeconds?: number;
    volume?: number;
    isVideoEffect?: boolean;
}

export type FINISH_VIDEO = 'FINISH_VIDEO';
export interface IFinishVideo {
    type: FINISH_VIDEO;
}

export type UPDATE_BUNDLES = 'UPDATE_BUNDLES';
export interface IUpdateBundles {
    type: UPDATE_BUNDLES;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    deniedBundlesByUniqueItems: number[];
    coupons: ICoupon[];
}

export type UPDATE_BUNDLES_PURCHASED_INFO = 'UPDATE_BUNDLES_PURCHASED_INFO';
export interface IUpdateBundlesPurchasedInfo {
    type: UPDATE_BUNDLES_PURCHASED_INFO;
    inventory: InventoryState;
}

export type SET_BUNDLE_IS_PURCHASED = 'SET_BUNDLE_IS_PURCHASED';
export interface ISetBundleIsPurchased {
    type: SET_BUNDLE_IS_PURCHASED;
    bundleId: number;
}

export type TRIGGER_GUIDE_SHOW = 'TRIGGER_GUIDE_SHOW';
export interface ITriggerGuideShow {
    type: TRIGGER_GUIDE_SHOW;
    name: GUIDE_NAMES;
}

export type BLUR_VIEW = 'BLUR_VIEW';
export interface IBlurView {
    type: BLUR_VIEW;
    isBlur: boolean;
}

export type HIDE_ESC = 'HIDE_ESC';
export interface IHideEscView {
    type: HIDE_ESC;
    hide: boolean;
}

export type IFRAME_SYNC_ENABLED = 'IFRAME_SYNC_ENABLED';
export interface IIframeSyncEnabled {
    type: IFRAME_SYNC_ENABLED;
    enabled: boolean;
}

/**
 *  Use for set background blur where:
 *  * 0 - toggle-off animated blur;
 *  * 1 - toggle-on animated blur;
 *  * 2 - default value;
 */
export enum BlurBackgroundStatus {
    OFF = 0,
    ON = 1,
    DEFAULT = 2,
}

export const BLUR_BACKGROUND = 'BLUR_BACKGROUND';
export interface IBlurBackground {
    type: typeof BLUR_BACKGROUND;
    isBlurBackground: BlurBackgroundStatus;
}

export type HIDDEN_MOBILE_NAVIGATE = 'HIDDEN_MOBILE_NAVIGATE';
export interface IHiddenMobileNavigate {
    type: HIDDEN_MOBILE_NAVIGATE;
    isHidden: boolean;
}

export type SELECT_GROUP_BUNDLE = 'SELECT_GROUP_BUNDLE';
export interface ISelectGroupBundle {
    type: SELECT_GROUP_BUNDLE;
    group: string;
    id: number;
}

export type SET_MENU = 'SET_MENU';
export interface ISetMenu {
    type: SET_MENU;
    menu: IMenuMap;
}

export type HIDDEN_MENU_ITEM = 'HIDDEN_MENU_ITEM';
export interface IHiddenMenuItem {
    type: HIDDEN_MENU_ITEM;
    menuItemKey: string;
}

export type SHOW_MENU_ITEM = 'SHOW_MENU_ITEM';
export interface IShowMenuItem {
    type: SHOW_MENU_ITEM;
    menuItemKey: string;
}

export type SET_CURRENT_NOTIFICATION_NAME = 'SET_CURRENT_NOTIFICATION_NAME';
export interface ISetCurrentNotificationName {
    type: SET_CURRENT_NOTIFICATION_NAME;
    name: string;
}

export type CHANGE_CURRENCIES_DIAPASON_FILTER = 'CHANGE_CURRENCIES_DIAPASON_FILTER';
export interface IChangeCurrenciesDiapasonFilter {
    type: CHANGE_CURRENCIES_DIAPASON_FILTER;
    category: ICategoryList;
    currency: string;
    min: number;
    max: number;
}

export type UPDATE_ACTIVE_FILTER_PRESET = 'UPDATE_ACTIVE_FILTER_PRESET';
export interface IUpdateActiveFilterPreset {
    type: UPDATE_ACTIVE_FILTER_PRESET;
    preset: string;
    withoutUpdateHistory: boolean;
}

export type SET_SORT_METHOD = 'SET_SORT_METHOD';
export interface ISetSortMethod {
    type: SET_SORT_METHOD;
    category: ICategoryList;
    method: ISortMethods;
    name: SortNames;
}

export type RESET_SORT_CATEGORY = 'RESET_SORT_CATEGORY';
export interface IResetSortCategory {
    type: RESET_SORT_CATEGORY;
    category: ICategoryList;
}

export type CHANGE_FILTERS_POSITION = 'CHANGE_FILTERS_POSITION';
export interface IChangeFiltersPosition {
    type: CHANGE_FILTERS_POSITION;
    position: FilterPosition;
}

export type CHANGE_MULTIPLE_CURRENCY_DIAPASON = 'CHANGE_MULTIPLE_CURRENCY_DIAPASON';
export type CHANGE_MULTIPLE_CURRENCY_DIAPASON_VALUES = {
    [key: string]: {
        min: number;
        max: number;
    };
};
export interface IChangeMultipleCurrencyDiapason {
    type: CHANGE_MULTIPLE_CURRENCY_DIAPASON;
    category: ICategoryList;
    values: CHANGE_MULTIPLE_CURRENCY_DIAPASON_VALUES;
}

export type SET_FILTERS_BY_QUERY_PARAMS = 'SET_FILTERS_BY_QUERY_PARAMS';
export type FILTERS = {
    [key: string]: string[];
};
export type SET_FILTERS_BY_QUERY = {
    filters?: FILTERS;
    coupons?: ICoupon[];
    currencyDiapason?: CHANGE_MULTIPLE_CURRENCY_DIAPASON_VALUES;
    presetName?: string;
};
export interface ISetFiltersByQueryParams {
    type: SET_FILTERS_BY_QUERY_PARAMS;
    category: ICategoryList;
    filters?: FILTERS;
    coupons?: ICoupon[];
    currencyDiapason?: CHANGE_MULTIPLE_CURRENCY_DIAPASON_VALUES;
    presetName?: string;
}

export type UPDATE_BUNDLE_PRICES_INFO = 'UPDATE_BUNDLE_PRICES_INFO';
export interface IUpdateBundlePricesInfo {
    type: UPDATE_BUNDLE_PRICES_INFO;
    category: ICategoryList;
    bundles: IBundleList;
}

export type INIT_CLIENT_SOURCE = 'INIT_CLIENT_SOURCE';
export interface IInitClientSource {
    type: INIT_CLIENT_SOURCE;
    source: keyof typeof CLIENT_BUTTON_COPYRIGHT_BY_ROUTE;
}

export type REMOVE_CLIENT_SOURCE = 'REMOVE_CLIENT_SOURCE';
export interface IRemoveClientSource {
    type: REMOVE_CLIENT_SOURCE;
}

export type SET_IFRAME_POPUP_NAME = 'SET_IFRAME_POPUP_NAME';
export interface ISetIframePopupName {
    type: SET_IFRAME_POPUP_NAME;
    name: string;
}

export type SET_TRUSTED = 'SET_TRUSTED';
export interface ISetTrusted {
    type: SET_TRUSTED;
}

export type UPDATE_ANIMATION_STATUS_CATEGORY = 'UPDATE_ANIMATION_STATUS_CATEGORY';
export interface IUpdateAnimationStatusCategory {
    type: UPDATE_ANIMATION_STATUS_CATEGORY;
    categoryName: ICategoryList;
    status: boolean;
}

export type UPDATE_SOUND_STATUS = 'UPDATE_SOUND_STATUS';
export interface IUpdateSoundStatus {
    type: UPDATE_SOUND_STATUS;
    soundKey: string;
    status: boolean;
}

export type FINISH_LOADING_RESOURCES = 'FINISH_LOADING_RESOURCES';
export interface IFinishLoadingResources {
    type: FINISH_LOADING_RESOURCES;
}

export type SET_MENU_VISIBILITY = 'SET_MENU_VISIBILITY';
export interface ISetMenuVisibility {
    type: SET_MENU_VISIBILITY;
    isVisible: boolean;
}

export type SET_FULLSCREEN = 'SET_FULLSCREEN';
export interface ISetFullscreen {
    type: SET_FULLSCREEN;
    isVisible: boolean;
}

export type UPDATE_BUNDLE_LIST = 'UPDATE_BUNDLE_LIST';
export interface IUpdateBundleList {
    type: UPDATE_BUNDLE_LIST;
    bundles: IBundleList;
}

export type UPDATE_CUSTOM_PAGE_SETTINGS = 'UPDATE_CUSTOM_PAGE_SETTINGS';
export interface IUpdateCustomPageSettings {
    type: UPDATE_CUSTOM_PAGE_SETTINGS;
    customPageSettings: CustomPageSettings;
}

export type UPDATE_CUSTOM_PAGE_BACKGROUND_SIZES = 'UPDATE_CUSTOM_PAGE_CURRENT_BACKGROUND_SIZES' | 'UPDATE_CUSTOM_PAGE_NATURAL_BACKGROUND_SIZES';
export interface IUpdateCustomPageBackgroundSizes {
    type: UPDATE_CUSTOM_PAGE_BACKGROUND_SIZES;
    sizes: BackgroundSizes;
}

export type UPDATE_CUSTOM_PAGE_POPUP_BACKGROUND = 'UPDATE_CUSTOM_PAGE_POPUP_BACKGROUND';
export interface IUpdateCustomPagePopupBackground {
    type: UPDATE_CUSTOM_PAGE_POPUP_BACKGROUND;
    url: string;
}

export type UPDATE_POPUP_SETTINGS = 'UPDATE_POPUP_SETTINGS';
export interface IUpdatePopupSettings {
    type: UPDATE_POPUP_SETTINGS;
    popupSettings: PopupSettings;
}

export type UPDATE_NESTED_POPUP_SETTINGS = 'UPDATE_NESTED_POPUP_SETTINGS';
export interface IUpdateNestedPopupSettings {
    type: UPDATE_NESTED_POPUP_SETTINGS;
    nestedPopup: PopupSettings['nestedPopup'];
}

export type TOGGLE_CATEGORY_TIMER = 'TOGGLE_CATEGORY_TIMER';
export interface IToggleCategoryTimer {
    type: TOGGLE_CATEGORY_TIMER;
    categoryName: string;
    value: boolean;
}

export type ActionApp =
    | IChangeCurrentPage
    | IFinishRequest
    | IChangeVisiblePort
    | IUpdateCategoryByFilter
    | IChangeVisiblePopup
    | IChangeFilter
    | IResetFilter
    | IResetCategoryFilter
    | IUpdateQuantity
    | IDisableCategory
    | ISetFetching
    | ISetVisibleInfoScreen
    | ISetRandomBundleAnimation
    | IChangeViewBackground
    | ISetVisibleTopPanel
    | IShowParallaxAnimationShip
    | IOnSearch
    | IResetSearch
    | IStartVideo
    | IFinishVideo
    | IChangeMultipleFilter
    | IUpdateBundles
    | ITriggerGuideShow
    | IBlurView
    | IHiddenMobileNavigate
    | ISelectGroupBundle
    | ISetMenu
    | IHiddenMenuItem
    | IShowMenuItem
    | ISetCurrentNotificationName
    | IChangeCurrenciesDiapasonFilter
    | IUpdateActiveFilterPreset
    | ISetSortMethod
    | IResetSortCategory
    | IChangeFiltersPosition
    | IChangeMultipleCurrencyDiapason
    | ISetFiltersByQueryParams
    | IUpdateBundlePricesInfo
    | IInitClientSource
    | IRemoveClientSource
    | ISetIframePopupName
    | ISetTrusted
    | IUpdateAnimationStatusCategory
    | IFinishLoadingResources
    | IUpdateSoundStatus
    | ISetMenuVisibility
    | ISetFullscreen
    | IUpdateBundleList
    | IUpdateBundlesPurchasedInfo
    | ISetBundleIsPurchased
    | IBlurBackground
    | IUpdateCustomPageSettings
    | IUpdateCustomPageBackgroundSizes
    | IUpdateCustomPagePopupBackground
    | IUpdatePopupSettings
    | IUpdateNestedPopupSettings
    | IToggleCategoryTimer
    | IHideEscView
    | IIframeSyncEnabled;
